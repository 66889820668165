<template>
  <pdf src="/files/AdminPanelBins.pdf" v-for="i in 4" :key="i" :page="i"></pdf>
</template>

<script>
import pdf from "vue3-pdf";
export default {
  components: {
    pdf,
  },
};
</script>

<style></style>
